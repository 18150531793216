// Gatsby supports TypeScript natively!
import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Equipo = () => {
  useEffect(() => {
    window.slider()
  }, [])
  return (
    <Layout title="Equipo" show_bread={true}>
      <SEO title="Equipo" />
      <div className={"section mcb-section equal-height"} style={{ paddingTop: "100px", paddingBottom: "60px" }}>
        <div className={"section_wrapper mcb-section-inner"}>
          <div className={"wrap mcb-wrap one  valign-top clearfix"}>
            <div className={"mcb-wrap-inner"}>
              <div className={"container-custom"}>
                <div className={"row"}>
                  <div className={"col-md-2"}></div>
                  <div className={"col-md-3"}>
                    <div className={"team-box box-5 shape lg"}>
                      <div className={"team-img rounded-img"}>
                        <img alt="" src={"/images/equipo/placido.jpg"} />
                      </div>
                      <div className={"team-details"}>
                        <h3 className={"team-name"}>Jorge Plácido</h3>
                        <h5 className={"team-pos main-color"}>Head of Research</h5>
                      </div>
                    </div>
                  </div>
                  <div className={"col-md-1"}></div>
                  <div className={"col-md-5"} style={{ paddingTop: "80px" }}>
                    <p>Nuestro director de análisis, Jorge Plácido, es uno de los analistas con mayor
                      experiencia en Latinoamérica. Se desempeñó durante 3 décadas, con una
                      carrera impecable, en el área de análisis de Vector CB, como director
                      del área, colaborando en más de un centenar de emisiones de deuda y
                      capital, así como valuando más de dos centenares de instrumentos de deuda y
                      capital (CKDs, acciones, Fibras, Fibras E, deuda convertible, etc.).</p>
                  </div>
                </div>
                <hr className={"no_line"} style={{ margin: "0 auto 30px" }} />
                <div className={"row"}>

                  <div className={"col-md-1"}></div>
                  <div className={"col-md-3"}>
                    <div className={"team-box box-5 shape lg"}>
                      <div className={"team-img rounded-img"}>
                        <img alt="" src={"/images/equipo/fernandez.jpg"} />
                      </div>
                      <div className={"team-details"}>
                        <h3 className={"team-name"}>Gonzalo Fernández</h3>
                        <h5 className={"team-pos main-color"}>Socio Senior</h5>
                      </div>
                    </div>
                  </div>
                  <div className={"col-md-1"}></div>
                  <div className={"col-md-3"}>
                    <div className={"team-box box-5 shape lg"}>
                      <div className={"team-img rounded-img"}>
                        <img alt="" src={"/images/equipo/lagunas.jpg"} />
                      </div>
                      <div className={"team-details"}>
                        <h3 className={"team-name"}>Jorge Lagunas</h3>
                        <h5 className={"team-pos main-color"}>CoDirector de Análisis</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"row"}>

                  {/*<div className={"col-md-3"}>*/}
                  {/*    <div className={"team-box box-5 shape lg"}>*/}
                  {/*        <div className={"team-img rounded-img"}>*/}
                  {/*            <img alt="" src={'images/equipo/benjamin.jpg'}/>*/}
                  {/*        </div>*/}
                  {/*        <div className={"team-details"}>*/}
                  {/*            <h3 className={"team-name"}>Benjamin Salgado</h3>*/}
                  {/*            <h5 className={"team-pos main-color"}>Analista de Sector</h5>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className={"col-md-1"}></div>
                  <div className={"col-md-3"}>
                    <div className={"team-box box-5 shape lg"}>
                      <div className={"team-img rounded-img"}>
                        <img alt="" src={"/images/equipo/okamoto.jpg"} />
                      </div>
                      <div className={"team-details"}>
                        <h3 className={"team-name"}>Kazuo Okamoto</h3>
                        <h5 className={"team-pos main-color"}>Analista Economía</h5>
                      </div>
                    </div>
                  </div>
                  <div className={"col-md-1"}></div>
                  <div className={"col-md-3"}>
                    <div className={"team-box box-5 shape lg"}>
                      <div className={"team-img rounded-img"}>
                        <img alt="" src={"images/equipo/alcaraz.jpg"} />
                      </div>
                      <div className={"team-details"}>
                        <h3 className={"team-name"}>Carlos Alcaraz</h3>
                        <h5 className={"team-pos main-color"}>Analista de Sector</h5>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Equipo
